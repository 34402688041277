// css
import '../../scss/pages/404.scss';

// utils
import foundation from '../_utils/foundation';
foundation();

// npm
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// libs
import FvTitle from '../_libs/FvTitle';
import ScrollFadeAnimation from '../_libs/ScrollFadeAnimation';
import LinePath from '../_libs/LinePath';

document.addEventListener('DOMContentLoaded', () => {
	FvTitle();
	ScrollFadeAnimation();

	setTimeout(() => {
		fv();
	}, 100);
});

window.addEventListener('load', () => {
	const line = new LinePath();
	line.observe();
});

function fv() {
	// アニメーション対象の要素を取得
	const itemsToAnimate = [];
	for (let i = 1; i <= 4; i++) {
		itemsToAnimate.push(document.querySelector(`.js-fv-item${i}`));
	}

	// 各要素に対してアニメーションを設定
	itemsToAnimate.forEach((item, index) => {
		if (!item) return; // 要素が存在しない場合はスキップ

		// 初期状態を設定
		gsap.set(item, {
			opacity: 0,
			yPercent: 30,
		});

		// アニメーションを設定
		gsap.to(item, {
			opacity: 1,
			yPercent: 0,
			duration: 1,
			ease: 'power4.out',
			delay: index * 0.15, // 各要素に0.15秒ずつ遅延を追加
			scrollTrigger: {
				trigger: item,
				start: 'top 90%',
				toggleActions: 'play none none none',
			},
			onComplete: () => {
				// アニメーション完了0.5秒後に実行
				setTimeout(() => {
					// 要素内のjs-repeat-itemクラスを持つ子要素を探す
					const repeatItem = item.querySelector('.js-repeat-item');
					// 子要素が存在すれば、is-activeクラスを追加
					if (repeatItem) {
						repeatItem.classList.add('is-active');
					}
				}, 500);
			},
		});
	});
}
